import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getImageWithTooltips } from '../services/api';
import TooltipModal from './TooltipModal';

function StaticImageView() {
    const { imageId } = useParams();
    const [image, setImage] = useState(null);
    const [selectedTooltip, setSelectedTooltip] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            const data = await getImageWithTooltips(imageId);
            setImage(data);
        };
        fetchImage();
    }, [imageId]);

    const handleTooltipClick = (e, tooltip) => {
        e.stopPropagation();
        setSelectedTooltip(tooltip);
    };

    if (!image) return <p>Loading...</p>;

    return (
        <div className="mainContainer" >
        <div className="shareImgMain" style={{marginTop: "1rem", marginBottom:"1rem"}} >
        <div style={{ width: "100%",
              position: "relative",
              overflow: "hidden",
              display: "inline-block",
              height: "auto", }}>
            <img
                src={image.url}
                alt="Shared"
                style={{
                   width: "100%", objectFit: "contain", display: "block"
                    // borderRadius: "1rem"
                }}
            />
            {image.tooltips?.map((tooltip, i) => (
                <div
                    key={i}
                    onClick={(e) => handleTooltipClick(e, tooltip)}
                    style={{
                        color: `${image.color}` || 'black',
                        position: 'absolute',
                        left: `${tooltip.x}%`, // Convert percentage to pixel-based positioning
                        top: `${tooltip.y}%`, 
                        cursor: 'pointer'
                    }}
                >
                    <span className="material-symbols-outlined" 
                    style={{
                        fontSize:  image.size === "small"
                          ? "0.75rem"
                          : image.size === "medium"
                          ? "1rem"
                          : image.size === "large"
                          ? "1.25rem"
                          : "1.25rem",
                          }}>
                        {image.iconStyle || 'radio_button_checked'}
                    </span>
                </div>
            ))}
            {selectedTooltip && (
                <TooltipModal
                    tooltip={selectedTooltip}
                    imageUrl={image.url}
                    onClose={() => setSelectedTooltip(null)}
                    isEditMode={false}
                />
            )}
        </div>
        </div>
        </div>
    );
}

export default StaticImageView;
