import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import "./css/ToolTipEditor.css";
import { useLocation, useNavigate } from "react-router-dom";
import { editTooltip } from "../services/api";

function TooltipEditor({ onSave, onClose, imageUrl }) {
  const navigate = useNavigate()
  const location = useLocation();
  const [tooltip, setTooltip] = useState({
    title: "",
    subtitle: "",
    description: "",
    links: ["", ""],
    innerImage: ""
  });
  const [preview, setPreview] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [urlErrors, setUrlErrors] = useState(["", ""]);
  const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;

  useEffect(() => {
    // console.log("imageId is", imageId)
    // Load tooltip data if passed from ToolTipModal
    if (location && location.state && location.state.tooltip) {
      console.log("state data is", location.state.tooltip);
      setTooltip(location.state.tooltip);
      setImageId(location.state.imageId);
    }
  }, [location ? location.state : " "]);


  const isFormValid = () => {
    return (
      tooltip.title.trim() !== "" &&
      tooltip.subtitle.trim() !== "" &&
      // tooltip.iconStyle !== "" &&
      // tooltip.size !== "" &&
      // tooltip.color !== "" &&
      tooltip.links.every(link => link.trim() !== "") && 
      urlErrors.every(error => error === "") 
    );
  };

  const handleChange = (e) =>
    setTooltip({ ...tooltip, [e.target.name]: e.target.value });

  const handleUrlChange = (index, value) => {
    const newLinks = [...tooltip.links];
    newLinks[index] = value;
    setTooltip({ ...tooltip, links: newLinks });

    // Validate the URL input
    const errors = [...urlErrors];
    if (value && !urlPattern.test(value)) {
      errors[index] = "Please enter a valid URL in the format: https://www.websitename.com";
    } else {
      errors[index] = "";
    }
    setUrlErrors(errors);
  };

  const handleSave = async () => {
    if (isFormValid()) {
        setIsSaving(true);
        try {
            await onSave(tooltip);
        } finally {
            setIsSaving(false);
        }
    } else {
        alert("Please fill in all required fields");
    }
};

const handleEdit = async () => {
  if (!tooltip._id) {
    alert("Tooltip ID is missing");
    return;
  }

  if (!isFormValid()) {
    alert("Please fill in all required fields");
    return;
  }

  setIsSaving(true);

  try {
    const updatedTooltip = {
      title: tooltip.title,
      subtitle: tooltip.subtitle,
      description: tooltip.description,
      links: tooltip.links,
      innerImage: tooltip.innerImage, // This should be the base64 string if edited
    };

    // Call the edit API helper with the tooltip ID and updated data
    await editTooltip(tooltip._id, updatedTooltip);

    // Navigate back or update state to reflect the changes
    // alert("Tooltip updated successfully!");
    navigate(`/edit/${imageId}`);
  } catch (error) {
    console.error("Error updating tooltip:", error);
    alert("Failed to update tooltip. Please try again.");
  } finally {
    setIsSaving(false);
  }
};



  const handleInnerImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);

      const reader = new FileReader();
      reader.onloadend = () => {
        setTooltip({ ...tooltip, innerImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <button style={closeButtonStyle} onClick={onClose}><b>X</b></button>
        <div className="tooltip-main">
          <div className="tooltip-form">
            {/* Display innerImage if available, otherwise fallback to imageUrl */}
            {(tooltip.innerImage || imageUrl) && (
              // <img
              //   className="tooltip-image"
              //   src={tooltip.innerImage || imageUrl}
              //   alt="Tooltip"
              //   style={{
              //     width: tooltip.size === "small" ? "200px" :
              //           tooltip.size === "medium" ? "350px" : 
              //           tooltip.size === "large" ? "500px" : "600px"
              //   }}
              // />
              <label htmlFor="imageUpload" className="upload-boxx">
              <input
              type="file"
              accept="image/*"
              onChange={handleInnerImageChange}
              id="imageUpload"
              className="hidden-input"
            />
           {preview || tooltip.innerImage ? (
      <img
        src={preview || tooltip.innerImage}
        alt="Preview"
        style={{
          maxWidth: '97%',
          maxHeight: '440px',
          objectFit: 'contain'
        }}
      />
    ) : (
      <div className="upload-placeholder">
        <p>Drag and Drop image here</p>
        <p>or</p>
        <span>Upload from Computer</span>
      </div>
    )}
            </label>
            )}
            <div className="tooltip-main-fields">
              {/* <div style={{ flexDirection: "column", display: 'flex' }}> */}
                
                <input
                  className="tooltip-input"
                  name="title"
                  placeholder="Title"
                  value={tooltip.title || ""}
                  onChange={handleChange}
                  style={{
                    fontSize: "1.5rem",
                    padding: 10,
                  }}
                />
                <input
                  className="tooltip-input"
                  name="subtitle"
                  value={tooltip.subtitle || ""}
                  placeholder="Subtitle"
                  onChange={handleChange}
                  style={{
                    fontSize: "0.8rem",
                    // margin: 0,
                    padding: 6,
                  }}
                />

                <textarea
                  maxLength={250}
                  className="tooltip-input tooltip-textarea"
                  name="description"
                  value={tooltip.description || ""}
                  placeholder="Description"
                  onChange={handleChange}
                  style={{
                    fontSize: "0.8rem",
                    paddingBottom:"6rem",
                  }}
                />

                {/* <ReactQuill
  value={tooltip.description || ""}
  onChange={(value) => setTooltip({ ...tooltip, description: value })}
  placeholder="Write your description here..."
  style={{
    height: "200px",
    marginBottom: "20px",
    backgroundColor: "white",
  }}
/> */}
              {/* </div> */}
              {/* <div className="tooltip-links">
                {tooltip.links.map((_, i) => (
                  <input
                    key={i}
                    type="link"
                    className="tooltip-input"
                    placeholder={`URL ${i + 1}`}
                    onChange={(e) => {
                      const newLinks = [...tooltip.links];
                      newLinks[i] = e.target.value;
                      setTooltip({ ...tooltip, links: newLinks });
                    }}
                  />
                ))}
              </div> */}

<div className="tooltip-links">
    <div>
        <label style={{ fontWeight: 'bold', color:"grey",display: 'block', marginBottom: '5px' }}>Inquire</label>
        <input
            key={0}
            type="link"
            className="tooltip-input"
            value={tooltip.links[0] || ""}
            placeholder="URL 1"
            onChange={(e) => {
                const newLinks = [...tooltip.links];
                newLinks[0] = e.target.value;
                setTooltip({ ...tooltip, links: newLinks });
                handleUrlChange(0, e.target.value);
            }}
        />
                          {urlErrors[0] && <span style={{ color: 'red', fontSize: '0.8rem' }}>{urlErrors[0]}</span>}
    </div>
    <div>
        <label style={{ fontWeight: 'bold',  color:"grey",display: 'block', marginBottom: '5px' }}>Contact Us</label>
        <input
            key={1}
            type="link"
            className="tooltip-input"
            value={tooltip.links[1] || ""}
            placeholder="URL 2"
            onChange={(e) => {
                const newLinks = [...tooltip.links];
                newLinks[1] = e.target.value;
                setTooltip({ ...tooltip, links: newLinks });
                handleUrlChange(1, e.target.value)
            }}
        />
      {urlErrors[1] && <span style={{ color: 'red', fontSize: '0.8rem' }}>{urlErrors[1]}</span>}
    </div>
</div>


      

              {/* Other Options */}
  
            </div>
          </div>
        </div>
              <div className="finishButton">
              {location && location.state && location.state.tooltip._id ? (
    <button
      className={`save-tooltip-btn ${!isFormValid() ? "disabled" : ""}`}
      onClick={handleEdit}
      disabled={!isFormValid() || isSaving}
      title={!isFormValid() ? "Please fill in all required fields" : ""}
    >
      {isSaving ? <CircularProgress size={24} color="inherit" /> : "Update"}
    </button>
  ) : (
    <button
      className={`save-tooltip-btn ${!isFormValid() ? "disabled" : ""}`}
      onClick={handleSave}
      disabled={!isFormValid() || isSaving}
      title={!isFormValid() ? "Please fill in all required fields" : ""}
    >
      {isSaving ? <CircularProgress size={24} color="inherit" /> : "Save"}
    </button>
  )}
       
                <div className="tooltip-container">
                  {!isFormValid() && <span className="tooltip-text">Please fill in all required fields</span>}
                </div>
              </div>
      </div>
    </div>
  );
}

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflowY: "auto",
};

const modalContentStyle = {
  backgroundColor: "white",
  // padding: "0.25rem",
  borderRadius: "8px",
  width: "95%",
  maxWidth: "1100px",
  height: "auto",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  backgroundColor: "black",
  borderRadius: "50%",
  color: "white",
  border: "none",
  fontWeight: 'bold',
};

export default TooltipEditor;
