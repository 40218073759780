import { useState } from "react"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
import Swal from "sweetalert2"
import "./css/LoginForm.css"
import { setToken } from "../redux/authSlice"
import { useDispatch } from "react-redux"


function LoginForm(){
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)


    async function handleLogin(e){
        e.preventDefault()
        try {
            const requestBody = {email, password}
            const response = await axios.post('https://360lovelife.com/api/auth/login', requestBody)
            if (response) {
                dispatch(setToken(response.data.token)); 
                localStorage.setItem('accessToken', response.data.token)
                localStorage.setItem('userId', response.data.user.id)
                // localStorage.setItem('username', response.data.user.firtname + response.data.user.lastname)
                localStorage.setItem('userEmail', response.data.user.email)
                Swal.fire({
                    icon: "success",
                    title: "Login successful!",
                    text: "You have successfully logged in!"
                });
                navigate('/home')
                
            }else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!"
                });
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error
            });
        }
    }

    return (
        <div className="login-container">
            <form onSubmit={handleLogin} className="login-form">
                <h2>Login to your account</h2>
                <p>Welcome back!</p>
                <div className="form-group">
                <label htmlFor="email" className="form-label">Email address</label>
                <input onChange={e => {setEmail(e.target.value)}} type="email" className="form-control" id="email" placeholder="JohnDoe@example.com"/>
                    <label htmlFor="password" className="form-label">Password</label>
                    <div className="password-input-container">
                        <input 
                            onChange={e => {setPassword(e.target.value)}} 
                            type={showPassword ? "text" : "password"} 
                            className="form-control" 
                            id="password" 
                            placeholder="********"
                        />
                        <button 
                            type="button" 
                            className="password-toggle"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? "Hide" : "Show"}
                        </button>
                    </div>
                    {/* <input onChange={e => {setPassword(e.target.value)}} type="password" className="form-control" id="password" placeholder="********"/> */}
                </div>
                <button type="submit" className="btn btn-primary">LOG IN</button>
                {/* <p>Don't have an account? <Link to={'/register'}>Create an account</Link></p> */}
            </form>
        </div>
    )
}

export default LoginForm