
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import { deleteTooltip } from '../services/api';
import "./css/ToolTipModal.css"


function TooltipModal({ tooltip, imageUrl, onClose, onDelete, imageId, isEditMode = false }) {
    const navigate = useNavigate();
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleEdit = () => {
        navigate(`/tooltip/edit/${tooltip._id}`, { state: { tooltip, imageId } });
    };

    const confirmDelete = async () => {
        setShowConfirmModal(false); // Close confirmation modal
        await deleteTooltip(tooltip._id); // Execute the passed delete handler
        onDelete();
        onClose();
    };

    const handleDelete = () => {
        setShowConfirmModal(true); // Show confirmation modal
    };

    const isMobile = window.innerWidth <= 768;
const responsiveStyles = isMobile ? mobileStyles : {};

const descriptionPoints = tooltip  
  ? tooltip.description && tooltip.description.split('\n').filter(point => point.trim() !== '')
  : [];
  
  useEffect(() => {
      console.log("tooltip data is", tooltip.innerImage);})
      return (
        <>
      <div style={modalStyle}>
    <div style={modalContentStyle}>
        <button style={closeButtonStyle} onClick={onClose}>
            <b>X</b>
        </button>
        <div style={contentContainerStyle}>
            {tooltip.innerImage ? (
                <img src={`${tooltip.innerImage}`} alt="Uploaded Tooltip" style={imageStyle} />
            ) : (
                <p>No image provided</p>
            )}
            <div style={infoStyle}>
                <div style={titleContainerStyle}>
                    <h2 style={titleStyle}>{tooltip.title}</h2>
                    <h4 style={subtitleStyle}>{tooltip.subtitle}</h4>
                </div>
                <div style={descriptionContainerStyle} className="hide-scrollbar">
                    <ul style={descriptionListStyle}>
                        {descriptionPoints.map((point, index) => (
                            <li key={index} style={descriptionItemStyle}>
                                {point.trim()}
                            </li>
                        ))}
                    </ul>
                </div>
                <div style={linkContainerStyle}>
                    <a href={tooltip.links[0]} style={linkStyle}>More Info</a>
                    <a href={tooltip.links[1]} style={linkStyle}>Contact Us</a>
                </div>
            </div>
        </div>
        {isEditMode && (
            <div style={buttonContainerStyle}>
                <button style={deleteButtonStyle} onClick={handleDelete}>Delete</button>
                <button style={editButtonStyle} onClick={handleEdit}>Edit</button>
            </div>
        )}
    </div>
</div>

            {showConfirmModal && (
                <ConfirmationModal
                    message="Are you sure you want to delete this tooltip?"
                    onConfirm={confirmDelete}
                    onCancel={() => setShowConfirmModal(false)}
                />
            )}
        </>
    );
}

const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const modalContentStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '800px',
    // maxHeight: '90vh',
    height: '500px', // Fixed height
    // overflowY: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
};

const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    fontWeight: 'bold',
};

const contentContainerStyle = {
    display: 'flex',
    gap: '20px',
    height: '97%',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: '15px'
    }
    // alignItems: 'center',
};

const imageStyle = {
    width: '55%',
    // minHeight: '400px',
    // maxHeight: '80vh',
    height: '500px', // Fixed height
    // objectFit: 'cover',
    // borderRadius: '8px',
    '@media (max-width: 768px)': {
        width: '100%',
        height: '200px'
    }
};

const buttonContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  };
  
  const editButtonStyle = {
    backgroundColor: "Transparent",
    color: "#007bff",
    border: "none",
    // borderRadius: "4px",
    // padding: "10px 20px",
    cursor: "pointer",
  };
  
  const deleteButtonStyle = {
    backgroundColor: "Transparent",
    color: "#dc3545",
    border: "none",
    // borderRadius: "4px",
    // padding: "10px 20px",
    cursor: "pointer",
  };
  
  

const infoStyle = {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Changed from space-evenly
    height: '500px', // Added to ensure full height usage
    '@media (max-width: 768px)': {
        width: '100%',
        minHeight: 'auto',
        padding: '10px'
    }
};

const titleContainerStyle = {
    marginBottom: '1rem' // Adds space between title section and description
};

const titleStyle = {
    textAlign: "left",
    marginTop: '0px',    // Added to ensure no extra space from top
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '5px',  // Reduced from 10px
};

const subtitleStyle = {
    textAlign: "left",
    fontSize: '18px',
    color: '#555',
    marginTop: '0px',    // Added to ensure no extra space from top
    marginBottom: '3rem'  // Keep this the same to maintain spacing with description
};

const descriptionContainerStyle = {
    flex: 1,
    overflowY: 'auto',
    marginBottom: '1rem',
    msOverflowStyle: 'none',  // IE and Edge
    scrollbarWidth: 'none',   // Firefox
    '&::-webkit-scrollbar': { // Chrome, Safari and Opera
        display: 'none'
    }

};

const descriptionStyle = {
    textAlign: "left",
    fontSize: '16px',
    marginBottom: '10rem',
};



const linkContainerStyle = { 
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    marginTop: 'auto',
    textAlign: 'left',
};

const linkStyle = {
    marginBottom: 0,
    fontSize: '16px',
    color: '#007bff',
    textDecoration: 'none',
};

const mobileStyles = {
    modalContentStyle: {
        width: '95%',
        padding: '15px',
    },
    contentContainerStyle: {
        flexDirection: 'column',
        gap: '15px',
    },
    imageStyle: {
        width: '100%',
        minHeight: '200px',
    },
    infoStyle: {
        width: '100%',
        minHeight: 'auto',
        padding: '10px',
    },
    linkContainerStyle: {
        marginTop: '1.5rem',
    }

};

const descriptionListStyle = {
    textAlign: "left",
    fontSize: "16px",
    // marginBottom: "1rem",
    paddingLeft: "1.5rem",
    listStyleType: "disc",
    margin: 0

};

const descriptionItemStyle = {
    marginBottom: "0.5rem",
    listStyleType:"none"
};

export default TooltipModal;
