import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Upload from "./pages/Upload";
import EditImage from "./pages/EditImage";
import ShareImage from "./pages/ShareImage";
import "./App.css";
import LoginForm from "./pages/LoginForm";
import EmbeddedView from "./components/EmbeddedView";
import StaticImageView from "./components/StaticImageView";
import TooltipEditor from "./components/ToolTipEditor";

const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  return (
    <Router>
      {/* <Navigation /> */}
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <ProtectedRoute>
              <Upload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit/:imageId"
          element={
            <ProtectedRoute>
              <EditImage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/share/:imageId"
          element={
            // <ProtectedRoute>
            <ShareImage />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/tooltip/edit/:tooltipId"
          element={
            <ProtectedRoute>
              <TooltipEditor />
            </ProtectedRoute>
          }
        />

        <Route path="/embed/:imageId" element={<EmbeddedView />} />
        <Route path="/static/:imageId" element={<StaticImageView />} />
      </Routes>
    </Router>
  );
}

export default App;
